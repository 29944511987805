.ck-editor__editable {
  min-height: 300px;
}

.ck ol > li,
.ck ul > li {
  list-style-position: inside !important;
}

.ck ul > li {
  list-style: disc;
}
