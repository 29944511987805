label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  .card-wrapper {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    min-width: 500px;
    @include media('<md') {
      min-width: 320px;
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      width: 50%;

      @include media('<md') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right{
  .ant-form-item-control{
    flex-direction: row;
      justify-content: flex-start;
  }
  .ant-upload-list-picture-card-container, .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.ant-card-head {
  background-color: #4f66a0;
  color: #ffffff;
  text-transform: capitalize;
}

.ant-picker-large {
  padding: 5px 11px 6px;
}
